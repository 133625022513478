<template>
  <div class="Recharge">
    <van-nav-bar
        title="充值"
        left-arrow
        @click-left="onClickLeft"
    />
    <div class="wallent_content">
      <van-cell :border="false" title-class="content_money">
        <template #title>
          我的余额：<span>&yen;</span>{{userinfo.credit2}}
        </template>
      </van-cell>
      <van-form @submit="onSubmit">
        <van-cell title="充值金额" :border="false"></van-cell>
        <van-field
          v-model="money"
          ref="moneys"
          name="money"
          type="number"
          :error="noMoney"
          :error-message="noText"
        >
          <template #label>&yen;</template>
        </van-field>
        <van-field :border="false">
          <template #input>
            <div class="money_list">
              <van-button plain hairline native-type="button" @click="checked(100)" :type="moneychecked == 100 ? 'primary' : ''">100</van-button>
              <van-button plain hairline native-type="button" @click="checked(200)" :type="moneychecked == 200 ? 'primary' : ''">200</van-button>
              <van-button plain hairline native-type="button" @click="checked(500)" :type="moneychecked == 500 ? 'primary' : ''">500</van-button>
              <van-button plain hairline native-type="button" @click="checked(1000)" :type="moneychecked == 1000 ? 'primary' : ''">1000</van-button>
              <van-button plain hairline native-type="button" @click="checked(2000)" :type="moneychecked == 2000 ? 'primary' : ''">2000</van-button>
              <van-button plain hairline native-type="button" @click="checked(5000)" :type="moneychecked == 5000 ? 'primary' : ''">5000</van-button>
              <van-button plain hairline native-type="button" @click="checked(10000)" :type="moneychecked == 10000 ? 'primary' : ''">10000</van-button>
            </div>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Recharge",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      iswx: false,
      islogin: false,
      money: "", // 充值金额
      moneychecked: "",
      noMoney: false,
      noText: "",
      userinfo: {}
    }
  },
  mounted() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "余额");
    setTimeout(() => {
      this.$refs.moneys.focus();
    }, 100);
    this.getinfo();
  },
  methods: {
    // 获取用户余额
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getMoney")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.userinfo = res.data.data;
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "获取数据失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 微信充值
    wxchongzhi() {
      this.$router.push({ name: "Recharge", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 支付宝充值
    zfbchongzhi() {
      this.$toast("暂未开放");
    },
    // 银行卡转账
    yhkchongzhi() {
      this.$toast("暂未开放");
    },
    // 执行充值接口
    onSubmit() {
      console.log(this.money.length, this.money);
      if(this.money.length <= 0 || parseFloat(this.money) <= 0) {
        this.noMoney = true;
        this.noText = "请输入充值金额";
        this.$refs.moneys.focus();
        return false;
      }else {
        this.noMoney = false;
        this.noText = "";
      }
      console.log("充值接口");
    },
    checked(money) {
      console.log(money);
      this.moneychecked = money;
      this.money = money;
    }
  }
}
</script>
<style lang="less">
.Recharge {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #333;
    }
  }
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        padding-top: 10px;
        color: #333;
        font-size: 16px;
        span {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .van-form {
      margin-top: 20px;
      .van-cell {
        .van-cell__title {
          text-align: left;
          font-weight: 500;
        }
      }
      .van-field {
        line-height: 30px;
        font-weight: bold;
        font-size: 20px;
        .van-field__label {
          flex: none;
          width: 20px;
        }
        .van-field__value {
          flex: 1;
          .money_list {
            padding-top: 10px;
            width: 100%;
            overflow: hidden;
            .van-button {
              margin: 0 10px 10px 0;
              width: 70px;
            }
          }
        }
      }
    }
  }
}
</style>